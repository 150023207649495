import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import * as styles from "../modules/projects.module.scss"

import Layout from "../modules/layout"
import Seo from "../components/Seo"
import Projects from "../modules/projects"

import BackgroundImage from "gatsby-background-image"

export default class Template extends Component {
  render() { 
		const project = this.props.data.contentfulProject
		
		return (
			<Layout>
				<Seo
					title={project.title}
					description={project.description.childMarkdownRemark.html}
				/>
				<section className={styles.container}>
					<div className={styles.project}>
						<div className={styles.projectInfo}>
							<h1 className={styles.projectTitle}>{project.title}</h1>
							<ul className={`${styles.projectTags} pilled`}>
								{
									project.tags && project.tags.map((tag, key) =>
										<li key={key}>{tag}</li>
									)
								}
							</ul>
							<div className={styles.projectDescription} dangerouslySetInnerHTML={{ __html: project.description.childMarkdownRemark.html }}></div>
						</div>
						<div className={styles.projectImageSlider}>
							{
								project.images && project.images.map((image, key) =>
									<div 
										className={styles.projectImageContainer}
										key={key}
										>
										<BackgroundImage 
											className={styles.projectImage}
											fluid={image.fluid} 
										/>
									</div>
								)
							}
						</div>
					</div>
				</section>
				<section>
					<div className="section-title-bar">
						<h3>My other work...</h3>
						<Link to="/work">View all work</Link>
					</div>
      				<Projects limit={3} excludes={[project.id]} />
				</section>
			</Layout>
		)
	}
}

export const templateQuery = graphql`
  query($id: String!) {
    contentfulProject(id: { eq: $id }) {
			id
			title
			tags
			description {
				childMarkdownRemark {
					html
				}
			}
			images {
				fluid {
					...GatsbyContentfulFluid_withWebp
				}
			}
    }
  }
`